<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="spotHallDetailPageBox">
    <SmallHeader :basic-information="basicInformation" />
    <!-- 有商品时候的展示    -->
    <div v-if="isHaveGoods" class="content-box">
      <div class="productDetails">
        <!-- 左边图片展示 -->
        <div class="imageBox">
          <!-- 大图 -->
          <div class="productImg">
            <pic-zoom
              v-if="(activeImg.indexOf('http')>=0)"
              :url="activeImg"
              :scale="2"
            />
            <pic-zoom
              v-else
              :url="$fileUrl + activeImg"
              :scale="2"
            />
          </div>
          <!-- 小图 -->
          <div class="smallImgBox">
            <span
              v-for="(item, index) in detailsObj.tradeGoodsImgList"
              :key="index"
            >
              <img
                v-if="(item.url.indexOf('http')>=0)"
                :class="activeIndex === index ? 'active smallImg' : 'smallImg'"
                :src=" item.url"
                alt="产品图片"
                @click="getBigImage(item, index)"
              >
              <img
                v-else
                :class="activeIndex === index ? 'active smallImg' : 'smallImg'"
                :src="$fileUrl + item.url"
                alt="产品图片"
                @click="getBigImage(item, index)"
              >
            </span>
          </div>
        </div>
        <!-- 右边详情展示 -->
        <div class="detailsShowBox">
          <!-- 商品的信息 -->
          <div class="specificationGoods">
            <h3 class="goodsTitle elp-1">
              {{ detailsObj.twoLevelCategoryName + '&nbsp;&nbsp;' + detailsObj.goodsSpecName + '&nbsp;&nbsp;' + detailsObj.goodsMaterialName }}
            </h3>
            <!-- 类别 -->
            <p class="category">
              类别：<i>{{ detailsObj.oneLevelCategoryName + '/' + detailsObj.twoLevelCategoryName }}；</i>规格：<i>{{ detailsObj.goodsSpecName }}；</i>材质：<i>{{ detailsObj.goodsMaterialName }}；</i>上架时间：<i>{{ timestampToTime(detailsObj.launchTime || '') }}</i>
            </p>
            <div class="price-box">
              <!-- 单价 -->
              <p class="price">
                <el-tooltip class="item" effect="dark" content="该价格不含运费" placement="top-start">
                  <span class="left">含税单价<i class="el-icon-warning" /></span>
                </el-tooltip>
                <span>￥{{ detailsObj.unitPriceIncludingTax }}元/吨</span>
                <span>起订量：≥{{ detailsObj.minOrderQuantity }}吨</span>
              </p>
              <!-- 挂牌库存 -->
              <p class="inventory">
                <span class="left">挂牌库存</span>
                <span>{{ detailsObj.availableListingWeight || 0 }}吨</span>
              </p>
            </div>
          </div>
          <!-- 其他类型 -->
          <div class="otherInformation">
            <p class="item elp-1">
              <i>钢厂/厂家</i>{{ detailsObj.manufactor }}
            </p>
            <p class="item elp-1 transport">
              <i>运输</i>
              <span v-if="detailsObj.buyerPickUp === 1">
                <img class="type-icon" src="@/assets/img/mjzt.png" alt="">
                买家自提
              </span>
              <span v-if="detailsObj.merchantDistribution === 1">
                <img class="type-icon" src="@/assets/img/sjps.png" alt="">
                商家配送
              </span>
              <span v-if="detailsObj.transfer === 1">
                <img class="type-icon" src="@/assets/img/gh.png" alt="">
                过户
              </span>
            </p>
            <p class="item elp-1 border-bottom">
              <i>发货地</i>{{ detailsObj.placeProvince + detailsObj.placeCity + detailsObj.placeTown + detailsObj.placeDetailAddr + '-' + detailsObj.warehouseName }}
            </p>
            <p class="item elp-1">
              <i>数量</i>
              <el-input-number v-model="num" class="buy-number" size="small" :precision="3" :min="detailsObj.minOrderQuantity" :max="detailsObj.availableListingWeight" label="描述文字" @change="getBuyNum" />
            </p>
            <p class="item elp-1">
              <i>合计</i>共<span class="totalPrice">￥{{ totalPrice }}</span><i style="width: auto;">（含税价，不含运费）</i>
            </p>
            <!-- 两个按钮 -->
            <div class="buttonCombination">
              <div v-if="$store.state.tradeTimeState">
                <span class="buy default" @click="buyNowClick">立即购买</span>
                <span class="addCar default" @click="addToCart"><img src="@/assets/icon/cart.png" alt="png">加入购物车</span>
              </div>
              <div v-else>
                <el-popover placement="top-start" title="平台开市时间" width="100" trigger="hover" :content="`${$store.state.tradeTimeRule.tradeDay === '00' ? '工作日：' : '每天：' }`+ `${$store.state.tradeTimeRule.state === 1 ? $store.state.tradeTimeRule.openTime + '-' + $store.state.tradeTimeRule.closeTime : '00:00-23:59'}`">
                  <span slot="reference" :class="['default',{'not-buy': !$store.state.tradeTimeState}]">立即购买</span>
                </el-popover>
                <el-popover placement="top-start" title="平台开市时间" width="100" trigger="hover" :content="`${$store.state.tradeTimeRule.tradeDay === '00' ? '工作日：' : '每天：' }`+ $store.state.tradeTimeRule.openTime + '-' + $store.state.tradeTimeRule.closeTime">
                  <span slot="reference" class="addCar" :class="['default',{'not-buy': !$store.state.tradeTimeState}]">加入购物车</span>
                </el-popover>
              </div>
            </div>
            <p class="item elp-1">
              <i>供应商</i>{{ detailsObj.cmpName }}
              <el-popover placement="top-start" title="挂牌联系人" width="100" trigger="hover" :content="detailsObj.listingPerson + detailsObj.personPhone">
                <span slot="reference" class="immediately-consult">
                  <img class="type-icon" src="@/assets/img/contact.png" alt="">立即咨询
                </span>
              </el-popover>
            </p>
            <p class="item elp-1 transport">
              <i>支付方式</i><span><img class="type-icon" src="@/assets/img/qkzf.png" alt="">全款购买</span>&nbsp;&nbsp;<span><img class="type-icon" src="@/assets/img/bzjyf.png" alt="">保证金预付</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 商品介绍 -->
      <div class="productsIntroduction">
        <h3 class="title">
          <span v-for="(item, index) in titleArr" :key="index" :class="activeTitle===index?'active':''" @click="activeTitle=index">{{ item }}</span>
        </h3>
        <!-- 产品介绍 -->
        <div v-if="activeTitle===0">
          <!-- 产品型号 -->
          <h3 class="sub-title">
            商品详情
          </h3>
          <div class="productModel">
            <p class="item">
              <i>商品类别</i>{{ detailsObj.oneLevelCategoryName + '/' + detailsObj.twoLevelCategoryName }}
            </p>
            <p class="item">
              <i>材质</i>{{ detailsObj.goodsMaterialName }}
            </p>
            <p class="item">
              <i>规格</i>{{ detailsObj.goodsSpecName }}
            </p>
            <p class="item">
              <i>仓库名称</i>{{ detailsObj.warehouseName }}
            </p>
            <p class="item">
              <i>厂家</i>{{ detailsObj.manufactor }}
            </p>
            <p class="item">
              <i>计重方式</i>{{ detailsObj.weightStyle === '00' ? '过磅' : detailsObj.weightStyle === '01' ? '理计' : detailsObj.weightStyle === '02' ? '抄码' : '' }}
            </p>
            <p class="item">
              <i>开票</i><span>一票制；多票制</span>
            </p>
            <p class="item transport">
              <i>交易支持</i><span v-for="(item) in detailsObj.tradeAccountList" :key="item.settleBankId"><img v-if="item.settleBankId === 'LSBANK'" class="type-icon" src="@/assets/img/LSBANK.png" alt=""><img v-if="item.settleBankId === 'ICBC'" class="type-icon" src="@/assets/img/ICBC.png" alt=""><img v-if="item.settleBankId === 'ABC'" class="type-icon" src="@/assets/img/ABC.png" alt="">{{ item.settleBankName }} </span>
            </p>
          </div>
          <div class="goods-description">
            <div v-html="detailsObj.goodsDescription" />
          </div>
          <h3 class="sub-title">
            交货流程
          </h3>
          <img class="flowImg1" src="@/assets/image/liucheng.png" alt="图片">
        </div>
        <!-- 合作协议 -->
        <div v-if="activeTitle===1" class="cooperationAgreement">
          <h3 class="subTile">
            根据《中华人民共和国合同法》、《中华人民共和国产品质量法》的规定，小链科技供应链执行如下售后政策：
          </h3>
          <p class="item">
            1. 不适用无理由退货。特别提示：以行情涨跌等理由要求退货的，小链科技供应链将不予受理。
          </p>
          <p class="item">
            2. 若出现质量问题，请与小链科技供应链联系。
          </p>
          <p class="item">
            3. 若无法提货，请尽快与小链科技供应链联系。
          </p>
          <p class="item">
            4. 如果您对小链科技供应链有任何不满意的情况，可随时联系小链科技供应链客服。
          </p>
          <h3 class="subTile subTile2">
            客服热线：021-35906666      企业QQ：2852360956
          </h3>
          <h3 class="subTile">
            出现异议的流程
          </h3>
          <p class="item three-title">
            一、提出申请：
          </p>

          <p class="item">
            1. 数量异议：请于提货当日提出，货物一旦出库，不予受理。
          </p>
          <p class="item">
            2. 质量异议：请于提货后7日内以书面形式向我司提出异议。
          </p>
          <p class="item item3 three-title">
            二、提交相关材料：
          </p>
          <p class="item">
            1. 数量异议提供资料：出库码单、磅单
          </p>
          <p class="item">
            2. 质量异议提供资料：
          </p>
          <p class="item">
            &nbsp;&nbsp;&nbsp;&nbsp;建材类：检测报告、不合格指标、工地位置、订单吨位、质量异议吨位/总吨位。
          </p>
          <p class="item">
            &nbsp;&nbsp;&nbsp;&nbsp;其他类：货物照片、钢卷号照片、检测报告及经找钢审核后需要补充的其他资料 。
          </p>
          <!-- <h3 class="subTile">
            （客户必须提供书面盖章的质量异议报告给我司，否则不予处理。）
          </h3> -->
          <p class="item item2">
            注：收到相关材料信息后，小链科技供应链将于   2   个工作日内予以审核反馈
          </p>
        </div>
      </div>
    </div>
    <!-- 商品下架的时候展示的页面 -->
    <div v-else class="noGoods content-box">
      <img src="@/assets/image/noData.png" alt="icon">
      <p>抱歉，商品已下架~</p>
      <router-link :to="{path:'/'}">
        返回首页
      </router-link>
    </div>
  </div>
</template>

<script>
import { goodsHallDetailDetailSpotHallGoods } from '@/http/hangOut'
import { tradeShoppingCartAdd } from '@/http/shoppingCar'
import { queryShopBaseInfoDesc } from '@/http/storeManagement'
import { timestampToTime } from '@/unit/index'
import PicZoom from 'vue-piczoom'
import SmallHeader from '@/views/storeManagement/components/SmallHeader.vue'
export default {
  components: {
    PicZoom,
    SmallHeader
  },
  data() {
    return {
      timestampToTime,
      isHaveGoods: false,
      num: '',
      titleArr: ['商品介绍', '售后政策'],
      activeIndex: 0,
      activeTitle: 0,
      activeImg: '',
      // 详情数据
      detailsObj: {
        tradeGoodsImgList: [],
        listingPerson: '',
        personPhone: ''
      },
      // 合计
      totalPrice: 0,
      token: null,
      basicInformation: {},
      goods: {
        goodsListingId: '', // 挂牌ID
        purchaseWeight: '' // 购买数量
      },
      userInfo: {}
    }
  },
  created() {
    this.token = localStorage.getItem(`mallFrontDeskToken${this.$store.state.userCmpRole}`)
    if (this.$route.query.shopId) {
      queryShopBaseInfoDesc(this.$route.query.shopId || '', res => {
        // 如果是已关闭的话就不显示
        if (res.data && res.data.state !== '04') this.basicInformation = { ...res.data }
      })
    }
    if (this.$route.query.id) {
      this.isHaveGoods = true
      this.getDetail()
    }
    if (
      localStorage.getItem(`mallFrontDeskToken${this.$store.state.userCmpRole}`)
    ) {
      this.userInfo = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)) || {}
    }
    this.$store.dispatch('getTradeTimeState')
    this.$store.dispatch('getTradeTime')
  },
  methods: {
    getBigImage(item, index) {
      this.activeImg = item.url
      this.activeIndex = index
    },
    // 获取详情
    getDetail() {
      goodsHallDetailDetailSpotHallGoods(this.$route.query.id, res => {
        this.detailsObj = res.data
        this.activeImg = res.data.tradeGoodsImgList ? res.data.tradeGoodsImgList[0].url : require('@/assets/image/bg.png')
        this.totalPrice = res.data.unitPriceIncludingTax
        this.num = res.data.minOrderQuantity
      })
    },
    // 获取购买数量
    getBuyNum(num) {
      this.totalPrice = (this.detailsObj.unitPriceIncludingTax * num).toFixed(2)
    },
    // 加入购物车
    addToCart() {
      if (!this.token || !this.token.length) {
        sessionStorage.setItem('beforeLoginUrl', this.$route.fullPath)
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      switch (this.userInfo.cmpState) {
        case '01':
          this.infoMakeup()
          return
        case '-1':
          this.infoMakeup()
          return
        default:
        {
          this.goods.goodsListingId = this.detailsObj.id
          this.goods.purchaseWeight = this.num
          this.goods.buyerCmpId = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)).cmpId
          this.goods.buyerCmpName = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)).orgName
          tradeShoppingCartAdd(this.goods, () => {
            this.$store.dispatch('getCarNum')
            this.$confirm(`商品已成功加入购物车`, {
              confirmButtonText: '去购物车结算',
              type: 'success',
              showCancelButton: false
            })
              .then(() => {
                this.$router.push({ path: '/shoppingCart/index' })
              })
              .catch(() => { })
          })
          break
        }
      }
    },
    // 立即购买
    buyNowClick() {
      if (!this.token || !this.token.length) {
        sessionStorage.setItem('beforeLoginUrl', this.$route.fullPath)
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      switch (this.userInfo.cmpState) {
        case '01':
          this.infoMakeup()
          return
        case '-1':
          this.infoMakeup()
          return
        default:
        {
          if (this.num > this.detailsObj.availableListingWeight) return this.$message.warning('起订量大于挂牌库存，不可购买')
          this.$router.push({ path: '/orderManagement/index', query: { id: this.detailsObj.id, purchaseWeight: this.num, type: 'goodsBuy' }})
          break
        }
      }
    },
    infoMakeup() {
      this.$confirm(
        `亲爱的${this.userInfo.userName || this.userInfo.orgName}用户, 为了您能正常使用平台提供的功能，请尽快完善公司信息`,
        '信息未完善',
        {
          confirmButtonText: '立即完善',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orgStructure/enterpriseInformation`, '_blank')
      })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.spotHallDetailPageBox {
  .content-box{
    margin: auto;
    padding-top: 35px;
    width: $centerPlateWidth;
  }
  .noGoods{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    font-size: 14px;
    color: $mainFont;
    a{
      margin-top: 11px;
      color: $themeColors;
      text-decoration: underline;
      padding-bottom: 178px;
    }
    img{
      display: block;
      margin: 171px 0 29px;
      height: 141px;
      width: 162px;
    }
  }

// 商品介绍
  .productsIntroduction {
    margin-top: 8px;
    border: 1px solid rgba(231,231,231,1);
    padding-bottom: 24px;

    .cooperationAgreement {
      margin-top: 10px;
      padding: 32px 24px 0 24px;
      color: $mainFont;
      font-size: 14px;

      .item{
        margin-bottom: 8px;
        color: #666;
      }
      .item2{
        margin-bottom: 0;
        margin-top: 32px;
      }
      .item3{
        margin-top: 32px;
      }
      .subTile {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 18px;
      }
      .subTile2{
        margin-bottom: 16px;
        font-size: 14px;
      }
      .three-title {
        color: $mainFont;
        font-weight: 600;
      }
    }
    .sub-title {
      font-size: 18px;
      color: #333333;
      margin-bottom: 16px;
      padding-left: 24px;
      margin-top: 32px;
    }
    .flowImg1 {
      width: 1152px;
      margin: auto;
      margin-left: 24px;
    }

    .productModel {
      padding: 0 24px;
      box-sizing: border-box;
      font-size: 12px;
      color: $mainFont;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .item {
        margin-bottom: 16px;
        width: 383px;

        i {
          display: inline-block;
          color: $fontColor1;
          font-style: normal;
          width: 80px;
        }
      }
    }

    .title {
      display: flex;
      height: 42px;
      background-color: #F2F2F2;
      box-sizing: border-box;

      span {
        width: 88px;
        cursor: pointer;
        color: #666666;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 1s;
      }

      .active {
        background-color: #fff;
        color: $themeColors;
        border-top: 1px solid  $themeColors;
      }

      .active2 {
        width: 178px;
      }
    }
  }

  .productDetails {
    display: flex;

    .detailsShowBox {
      flex: 1;

      .buttonCombination {

        margin-bottom: 12px;
        padding-left: 136px;
        >div {
          display: flex;
          align-items: center;
          > span {
            margin-right: 8px;
          }
        }

        .default {
          display: inline-block;
          height: 47px;
          line-height: 45px;
          width: 129px;
          cursor: pointer;
          text-align: center;
          box-sizing: border-box;
          font-weight: 700;
          font-size: 18px;
        }

        .buy {
          width: 144px;
          color: $themeColors;
          border: 1px solid $themeColors;
        }

        .addCar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 198px;
          height: 47px;
          line-height: 45px;
          color: #fff;
          background-color: $themeColors;
          img{
            height: 24px;
            width: 24px;
            margin-right: 10px;
          }
        }
        .not-buy {
          background-color: $fontColor1;
          border: 1px solid  $fontColor1;
          color: #fff;
          cursor: default;
        }
      }

      .otherInformation {
        color: $mainFont;
        font-size: 14px;

        /deep/.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
        /deep/.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
        /deep/.el-input__inner:focus {
          border: 1px solid #E7E7E7 !important;
        }
        /deep/ .el-input-number__increase,
        /deep/ .el-input-number__decrease {
          height: 38px;
          width: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 22px;
          }
          &:not(.is-disabled) {
            i {
              color: #666666;
            }
          }
        }

        /deep/ .el-input-number {
          width: 152px;
          height: 40px;
          & .el-input > .el-input__inner {
            height: 40px;
            border-radius: 0;
          }
        }

        .item {
          margin-bottom: 16px;

          .totalPrice {
            color: $themeColors;
          }

          i {
            font-style: normal;
            color: $fontColor1;
            width: 120px;
            display: inline-block;
            padding-left: 16px;
          }

          .immediately-consult {
            color: $themeColors;
            display: inline-block;
            line-height: 19px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            margin-left: 8px;
          }

          .el-icon-success {
            font-size: 15px;
            color: #c1c1c1;
          }
        }
        .border-bottom {
          border-bottom: 1px solid #E7E7E7;
          padding-bottom: 16px;
        }
      }

      .specificationGoods {
        color: $fontColor1;
        font-size: 14px;

        .goodsTitle {
          margin-bottom:8px;
          width: 320px;
          color: $mainFont;
          font-size: 16px;
        }

        .category {
          color: #666;
          font-size: 14px;

          i {
            font-style: normal;
            margin-right: 30px;
          }
        }

        .price-box {
          padding: 16px;
          background: rgba(0,0,0,0.03);
          margin: 8px 0 16px;
          .left {
            width: 120px;
            display: inline-block;
            i{
              margin-left: 12px;
            }
          }
          .price {
            display: flex;
            align-items: center;
            span:nth-child(2) {
              font-size: 24px;
              color: $themeColors;
              font-weight: 700;
              margin-right: 12px;
            }
          }
          .inventory {
            padding-top: 8px;
            span:nth-child(2) {
              color: $mainFont;
              font-size: 16px;
            }
          }
        }
      }
    }

    .imageBox {
      width: 384px;
      padding-right: 64px;
      position: relative;

      .productImg {
        width: 100%;
        height: 384px;
        border: 1px solid $borderColor3;
        box-sizing: border-box;
        display: block;
      }

      .smallImgBox {
        padding-top: 16px;
        display: flex;
        align-items: center;
        overflow: hidden;

        .smallImg {
          display: block;
          height: 62px;
          width: 62px;
          border: 1px solid $borderColor3;
          cursor: pointer;
        }
        .smallImg:not(:last-child) {
          margin-right: 16px;
        }

        .active {
          border: 1px solid $ornamentColor;
        }
      }

      .big-img-box {
        position: absolute;
        top: 0;
        right: -400px;
        width: 384px;
        height: 428px;
        border: 1px solid $borderColor3;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .mark {
        width: 100px;
        height: 100px;
        background-color: rgba(0,0,0,0.2);
        position: absolute;
      }
    }

  }
}
.goods-description {
  width: 100%;
  box-sizing: border-box;
  // text-align: center;
  padding: 10px 0;
  padding:0 24px;
  /deep/ img {
    width: 100%;
  }
}
.flex-row {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.type-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 2px;
}
.transport {
  span:not(:nth-child(2)) {
    margin-left: 24px;
  }
}

/deep/ .magnifier-box {
  overflow: hidden !important;
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
  }
}
</style>
